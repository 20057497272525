import { JobsResponse } from "../pocketbase-types";
import { getJobImgUrl, getLastUpdated } from "./Helpers";
import "./Jobs.css";

const JobCard = ({job}: {job: JobsResponse}) => {
    let formattedTitle = job.title;
    if (job.title && window.innerWidth > 600 && job.title.length > 50) {
        formattedTitle = job.title.slice(0, 50) + "...";
    }
    return (
        <div className="job-card" >
            <div className="logo">
                <img src={getJobImgUrl(job)}/>
            </div>
            <div className="info">
                <div className="title">
                    <a href={job.apply_link} target="_blank">{formattedTitle}</a>
                </div>
                <div className="company">
                    <a href={job.company_url}>{job.company}</a>
                </div>
                {/* <div className="salary_range"></div> */}
               <div className="location">
                    {job.country && job.country.length > 0 && (
                        <div className="location-item">
                            {job.country.map((country, index) => (
                                <span key={index}>{country}</span>
                            ))}
                        </div>
                    )}
                    {job.remote && job.remote.trim() !== undefined &&(
                        <div className="location-item">
                            {job.remote}
                        </div>
                    )}
                    {job.location && job.location !== "" && (
                        <div className="location-item">
                            {"📍" + job.location}
                        </div>
                    )}
            </div> 
            </div>
            <div className="tags">
                {job.tags &&
                    job.tags.filter(tag => tag.length > 1 && tag !== "🧠 AI")
                            .slice(0, 4)
                            .map((tag, index) => (
                                <div className="tag-item" key={index}>
                                    {tag}
                                </div>
                            ))}
            </div>
            <div className="end">
                <a href={job.apply_link} target="_blank">
                    <div className="apply_button">Apply</div>
                </a>
                <div className="time">{getLastUpdated(job.updated_ats)}</div>
            </div>
        </div>
    );
};

export default JobCard;