import { useState, useEffect } from "react";
import SearchField from "./SearchField";
import "./Filter.css";
import { useTheme } from "../../ThemeContext";
import {
    JobsTagsOptions,
    JobsCountryOptions,
    JobsRemoteOptions,
} from "../pocketbase-types";

interface FilterProps {
    onFilterChange: (tags: { tags: string[]; locations: string[] }) => void;
}

function Filter(props: FilterProps) {
    const { onFilterChange } = props;
    const { isDarkTheme } = useTheme();

    const [selectedTags, setSelectedTags] = useState<any[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<any[]>([]);

    // Call the callback function whenever selectedOptions change

    useEffect(() => {
        const tags = selectedTags.map((option) => option.value);
        const locations = selectedLocation.map((option) => option.value);
        onFilterChange({ tags, locations });
    }, [selectedTags, selectedLocation]);

    return (
        <>
            <div>
                {/* <div className="filter-tags"> */}
                {/* {Object.keys(JobsTagsOptions).slice(0, 10).map((tag, index) => ( */}
                {/* // <div key={index} className="filter-tag"> */}
                {/* {tag} */}
                {/* </div> */}
                {/* // ))} */}
                {/* </div> */}
            </div>
            <div className="filter">
                <div className="filter-search-bars">
                    <img
                        className="filter-image-burger"
                        src={
                            !isDarkTheme
                                ? "/images/logo_bad.png"
                                : "/images/logo_good.png"
                        }
                    ></img>
                    <div className="filter-bar">
                        <SearchField
                            className="search"
                            jobEnums={JobsTagsOptions}
                            onChange={(options: any[]) =>
                                setSelectedTags(options)
                            }
                            defaultValue={"🔍 Tags"}
                        />
                    </div>
                    <div className="filter-bar">
                        <SearchField
                            className="search"
                            jobEnums={JobsCountryOptions}
                            onChange={(options: any[]) =>
                                setSelectedLocation(options)
                            }
                            defaultValue={"📍 Location"}
                        />
                    </div>
                </div>
                {/* Selected Tags */}
                <div className="filter-selected-tags">
                    {selectedTags.map((option, index) => (
                        <div key={index} className="filter-selected-tag">
                            {option.label}
                        </div>
                    ))}
                    {selectedLocation.map((option, index) => (
                        <div key={index} className="filter-selected-tag">
                            {option.label}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Filter;
