import Banner from "../components/banners/Banner";
import Jobs from "../components/jobs/Jobs";
import Footer from "../components/footer/Footer";

function Home() {
    return (
        <div className="home-page">
            <Banner isHomePage={true} isPostPage={false} />
            <Jobs />
            <Footer />
        </div>
    );
}

export default Home;
