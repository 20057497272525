import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useTheme } from "../../ThemeContext";
import Switch from "@mui/base/Switch";
import CustomSwitch from "./CustomSwitch";
import { Link } from "react-router-dom";
import "./Banner.css";

function Banner({
    isHomePage,
    isPostPage,
}: {
    isHomePage: boolean;
    isPostPage: boolean;
}) {
    const { isDarkTheme, toggleTheme } = useTheme();
    const [displayedText, setDisplayedText] = useState("");
    const isMobile = useMediaQuery({ maxWidth: 700 });
    const homeHeadline = "Your Remote AI Jobs Hub";
    const homeWords = [
        "Save Your Career With AI",
        "Don't Fear the Machine",
        "Jobs with AI Sprinkles",
        "Its Love At First Byte",
        "Get Ctrl Alt Hired!",
        "There's Job Security in AI",
        "Artificially Intelligent Jobs",
        "Death By Robots?",
        "Help AI Take Over Humanity",
        "Rise of the Machines",
        "Layoffs For Everyone",
        "Human jobs? Hah! That's Cute",
        "The Robots Are Coming",
        "Humans Replaced By Algorithms",
        "The Dystopian Career Fair",
        "The Future Is Jobless",
        "Ctrl+Alt+Hired or Fired?",
    ];
    const postAJobHeadline = "Post A Job In AI";
    const postAJobWords = [
        "Quick and Easy Job Postings",
        "Post. Connect. Hire. Simple.",
        "Recruit with Us, Recruit Right!",
        "Hire the Best in AI!",
        "Post Today, Hire Tomorrow!",
        "Unearth the Best AI Talent",
    ];
    const [words, setWords] = useState(isPostPage ? postAJobWords : homeWords);

    useEffect(() => {
        const root = document.documentElement;
        root.style.setProperty(
            "--accent-color",
            isDarkTheme ? "var(--dark-accent)" : "var(--light-accent)"
        );
        root.style.setProperty(
            "--text-color",
            isDarkTheme ? "var(--dark-color)" : "var(--light-color)"
        );
        root.style.setProperty(
            "--bg-color",
            isDarkTheme ? "var(--dark-bg)" : "var(--light-bg)"
        );
        root.style.setProperty(
            "--hl-color",
            isDarkTheme ? "var(--dark-hl)" : "var(--light-hl)"
        );
        root.style.setProperty(
            "--border",
            isDarkTheme ? "var(--dark-border)" : "var(--light-border"
        );
        root.style.setProperty(
            "--border-color",
            isDarkTheme
                ? "var(--dark-border-color)"
                : "var(--light-border-color"
        );
        root.style.setProperty(
            "--hover-border-color",
            isDarkTheme
                ? "var(--light-border-color)"
                : "var(--dark-border-color"
        );
    }, [isDarkTheme]);

    useEffect(() => {
        let isCancelled = false;

        const typingDelay = 60;
        const deleteLetterDelay = typingDelay;
        const nextWordDelay = 1000;
        const endTypingDelay = 2000;
        let currentWordIndex = 0;

        const delay = (ms: number) =>
            new Promise((resolve) => setTimeout(resolve, ms));

        const typeLetter = async (textIndex: number) => {
            if (isCancelled) return;

            const currentWord = words[currentWordIndex];
            setDisplayedText(currentWord.slice(0, textIndex));

            const nextIndex = textIndex + 1;

            if (nextIndex <= currentWord.length) {
                await delay(typingDelay);
                await typeLetter(nextIndex);
            } else {
                await delay(endTypingDelay);
                await deleteLetter(currentWord.length);
            }
        };

        const deleteLetter = async (textIndex: number) => {
            if (isCancelled) return;

            const currentWord = words[currentWordIndex];
            setDisplayedText(currentWord.slice(0, textIndex));

            const nextIndex = textIndex - 1;

            if (nextIndex >= 0) {
                await delay(deleteLetterDelay);
                await deleteLetter(nextIndex);
            } else {
                await delay(nextWordDelay);
                currentWordIndex = (currentWordIndex + 1) % words.length;
                await typeLetter(0);
            }
        };

        setDisplayedText(words[0]);
        delay(2000).then(() => typeLetter(words[0].length));
        return () => {
            isCancelled = true;
        };
    }, [words]);

    if (isHomePage) {
        return (
            <div className="banner-home">
                <div className="banner-title-home">
                    {homeHeadline}
                    <p className="typing-animation">
                        {displayedText}
                        <span className="cursor"> </span>
                    </p>
                    <p className="mission-statement">
                        Bridging the gap between your skills and the perfect AI
                        job. With <b> more than 1k</b> monthly curated listings{" "}
                        <b>AI Will Not Kill Us</b> is the top destination to
                        find and list AI jobs worldwide!
                    </p>
                    <div className="banner-featured">
                        <Switch
                            className="custom-switch-smaller"
                            component={CustomSwitch}
                            onClick={toggleTheme}
                            checked={isDarkTheme}
                        />
                        <Link className="featured-button" to="/post-a-job">
                            Get Featured For $349
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    if (isPostPage) {
        return (
            <div className="banner-post">
                <div className="banner-title-post">
                    {postAJobHeadline}
                    <p className="typing-animation">
                        {displayedText}
                        <span className="cursor"> </span>
                    </p>
                </div>
            </div>
        );
    }

    return null;
}

export default Banner;
