import { useState } from "react";
import {
    JobsCountryOptions,
    JobsRecord,
    JobsRemoteOptions,
    JobsTagsOptions,
} from "../pocketbase-types";
import TextField from "../../forms/common/TextField";
import CheckoutButton from "./CheckoutButton";
import ReactSelectField from "../../forms/common/ReactSelectField";
// import SalaryRangeSlider from "../../forms/common/SalarySlider";

import "../../forms/Forms.css";

function PostFields() {
    const [jobsFormData, setJobsFormData] = useState<JobsRecord>({
        title: "",
        company: "",
        logo: "",
        remote: JobsRemoteOptions["💻 Remote"],
        country: [JobsCountryOptions["🌎 Worldwide"]],
        location: "",
        min_salary: "",
        max_salary: "",
        apply_link: "",
        tags: [JobsTagsOptions["🧠 AI"]],
        updated_ats: new Date().toISOString(),
        id_ats: "",
        commitment: "",
        region: "",
        company_url: "",
        paid: false,
    });

    const [companyLogo, setCompanyLogo] = useState<File>();
    const handleCompanyLogoChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = event.target.files?.[0];
        if (file) {
            setCompanyLogo(file);
            console.log(file);
        }
    };

    return (
        <div className="forms">
            <div className="job_details">
                <h1>Job Details</h1>

                {/* Company Name - Text Form */}
                <div className="company_name">
                    <div className="label">
                        <p>👥 Company Name*</p>
                    </div>
                    <TextField
                        textArea={false}
                        onChange={(text) =>
                            setJobsFormData({
                                ...jobsFormData,
                                company: text,
                            })
                        }
                    />
                </div>

                {/* Job Title - Text Form */}
                <div className="job_title">
                    <div className="label">
                        <p>💼 Position*</p>
                    </div>
                    <TextField
                        textArea={false}
                        onChange={(text) =>
                            setJobsFormData({
                                ...jobsFormData,
                                title: text,
                            })
                        }
                    />
                </div>

                {/* Job Type - Select Form */}
                {/* <div className="job_type"> */}
                {/* <div className="label"> */}
                {/* <p>📋 Job Type*</p> */}
                {/* </div> */}
                {/* <ReactSelectField */}
                {/* // jobEnums={JobsTypeOptions} */}
                {/* // isMulti={false} */}
                {/* // maxValues={1} */}
                {/* // className={"react-select"} */}
                {/* // defaultValue={"👔 Full-time"} */}
                {/* // onChange={(selectedOption) => { */}
                {/* setJobsFormData({ */}
                {/* // ...jobsFormData, */}
                {/* // type: (selectedOption as any) */}
                {/* // .value as JobsTypeOptions, */}
                {/* // }); */}
                {/* // }} */}
                {/* // inputGptValue={gptType} */}
                {/* // /> */}
                {/* </div> */}

                <div className="skills">
                    <div className="label">
                        <p>🔧 Skills & Tags </p>
                        <span className="label-extra">
                            (maximum 3 in order of relevance){" "}
                        </span>
                    </div>
                    <ReactSelectField
                        jobEnums={JobsTagsOptions}
                        isMulti={true}
                        maxValues={10}
                        className={"react-select"}
                        defaultValue={"🧠 AI"}
                        onChange={(values) => {
                            setJobsFormData({
                                ...jobsFormData,
                                tags: [...values].map(
                                    ({ value }) => value as JobsTagsOptions
                                ),
                            });
                        }}
                    />
                </div>

                {/* Locations - Multiple Select Form */}
                <div className="locations">
                    <div className="label">
                        <p>📍 Remote* </p>
                    </div>
                    <ReactSelectField
                        jobEnums={JobsRemoteOptions}
                        isMulti={false}
                        maxValues={1}
                        className={"react-select"}
                        defaultValue={"Select Remote"}
                        onChange={(values) => {
                            setJobsFormData({
                                ...jobsFormData,
                                remote: values
                                    ? values.map(
                                          ({ value }) =>
                                              value as JobsRemoteOptions
                                      )
                                    : [],
                            });
                        }}
                    />
                </div>

                {/* Locations - Multiple Select Form */}
                <div className="locations">
                    <div className="label">
                        <p>📍 Location* </p>
                        <span className="label-extra">
                            (maximum 2 locations){" "}
                        </span>
                    </div>
                    <ReactSelectField
                        jobEnums={JobsCountryOptions}
                        isMulti={true}
                        maxValues={2}
                        className={"react-select"}
                        defaultValue={"🌎 Worldwide"}
                        onChange={(values) => {
                            setJobsFormData({
                                ...jobsFormData,
                                country: [...values].map(
                                    ({ value }) => value as JobsCountryOptions
                                ),
                            });
                        }}
                    />
                </div>

                {/* Company Logo - File Upload */}
                <div className="company_logo">
                    <div className="label">
                        <p>🖼️ Company Logo*</p>
                    </div>
                    <input
                        type="file"
                        id="fileInput"
                        onChange={handleCompanyLogoChange}
                    />
                </div>

                {/* Salary - Slider Form */}
                {/* <div className="salary"> */}
                {/* <div className="label"> */}
                {/* <p>💰 Salary Range*</p> */}
                {/* <span className="label-extra"> */}
                {/* (required by law as of 2021) */}
                {/* </span> */}
                {/* </div> */}
                {/* <SalaryRangeSlider */}
                {/* // onRangeChange={([minSalary, maxSalary]) => { */}
                {/* // setJobsFormData({ */}
                {/* // ...jobsFormData, */}
                {/* // salary_range: */}
                {/* // "$" + */}
                {/* // (minSalary / 1000).toString() + */}
                {/* // "-" + */}
                {/* // (maxSalary / 1000).toString() + */}
                {/* // "k", */}
                {/* // }); */}
                {/* // }} */}
                {/* // inputGptValue={gptSalary} */}
                {/* // /> */}
                {/* </div> */}

                {/* Apply Link - Text Form */}
                <div className="apply_link">
                    <div className="label">
                        <p>🔗 Apply Link or Email*</p>
                    </div>
                    <TextField
                        textArea={false}
                        onChange={(text) =>
                            setJobsFormData({
                                ...jobsFormData,
                                apply_link: text,
                            })
                        }
                    />
                </div>

                {/* Feedback - Text Form */}
                <div className="feedback">
                    <div className="label">
                        {/* <p>💬 Feedback</p> */}
                        <span className="label-extra">
                            {/* (give us any feedback! tell us what you want and we */}
                            {/* will add it) */}
                        </span>
                    </div>
                    {/* <TextField */}
                    {/* // textArea={false} */}
                    {/* // onChange={(text) => */}
                    {/* // setJobsFormData({ */}
                    {/* // ...jobsFormData, */}
                    {/* // feedback: text, */}
                    {/* // }) */}
                    {/* // } */}
                    {/* // /> */}
                </div>
            </div>

            <div className="checkout-button-wrapper">
                <CheckoutButton
                    jobsFormData={jobsFormData}
                    company_logo={companyLogo}
                />
            </div>
        </div>
    );
}

export default PostFields;
