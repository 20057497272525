import "./index.css";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from './ThemeContext';

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <ThemeProvider>
            <App />
        </ThemeProvider>
    </React.StrictMode>
);
