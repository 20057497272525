import React from "react";
import { Route, BrowserRouter } from "react-router-dom";
import GoogleAnalytics from "./GoogleAnalytics";
import Header from "./components/header/Header";
import Home from "./pages/Home";
import PostAJob from "./pages/PostAJob";
import TabContent from "./TabContent";
import "./App.css";

const App: React.FC = () => {
    const inActive = {
        title: "Planning to kill you...",
        favicon: "/favicon_bad.ico",
    };

    const active = {
        title: "AI, ML Jobs, Remote",
        favicon: "/favicon.ico",
    };

    return (
        <>
            <GoogleAnalytics />
            <TabContent inActive={inActive} active={active} />
            <BrowserRouter>
                <Route exact path="/">
                    <Header isHomePage={true} />
                    <Home />
                </Route>
                <Route exact path="/post-a-job/">
                    <PostAJob />
                </Route>
            </BrowserRouter>
        </>
    );
};

export default App;
