import Select from "react-select";
import { useState } from "react";
import { JobsCountryOptions, JobsRemoteOptions, JobsTagsOptions } from "../pocketbase-types";

interface SearchFieldProps {
    jobEnums: typeof JobsTagsOptions |
    typeof JobsCountryOptions |
    typeof JobsRemoteOptions;
    className: string;
    onChange: (selectedOptions: any[]) => void;
    defaultValue: string;
}

function SearchField(props: SearchFieldProps) {
    const { jobEnums, className, onChange, defaultValue } = props;

    type Option = { label: string; value: string };
    const options: Option[] = jobEnums && Object.values(jobEnums).map((value) => ({
        label: value,
        value,
    }));
    const [optionSelected, setOptionSelected] = useState<Option[]>();

    const styleProxy = new Proxy(
        {},
        {
            get: (target, propKey) => () => { },
        }
    );

    // Define a function to handle changes to the React Select component.
    const handleOptionChange = (selectedOptions: Option | any) => {
        // Set the new state for optionSelected.
        const newOptions = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
        setOptionSelected(newOptions);
        // console.log(optionSelected)

        // Call the onChange callback with the new options.
        onChange(newOptions);
    };

    return (
        <Select
            options={options}
            components={{IndicatorSeparator: null }}
            // controlShouldRenderValue={false}
            value={optionSelected}
            onChange={handleOptionChange}
            className={className + "-container"}
            classNamePrefix={className}
            placeholder={defaultValue}
            isMulti={false} 
            tabSelectsValue={false}
            hideSelectedOptions={true}
            // defaultMenuIsOpen={true} // Debug
        // styles={styleProxy} // TODO: Use default and style from scratch
        />
    );
}

export default SearchField;
