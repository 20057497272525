import { Link } from "react-router-dom";
import "./Header.css";
import { useTheme } from "../../ThemeContext";

interface HeaderProps {
    isHomePage: boolean;
}

function Header({ isHomePage }: HeaderProps) {
    const { isDarkTheme, toggleTheme } = useTheme();

    return (
        <div className="header">
            <div className="header-wrapper">
                <Link className="header-logo" to="/">
                    <img
                        className="header-logo-image"
                        src={
                            isDarkTheme
                                ? "/images/logo_good.png"
                                : "/images/logo_bad.png"
                        }
                        alt="Logo"
                    />
                    <div className="header-logo-text">{"Will Not Kill Us"}</div>
                </Link>
            </div>
            <div className="header-nav">
                <Link className="default-nav-item" to="/survey">
                    Will AI Kill Us?
                </Link>
                <Link className="default-nav-item" to="/about-us">
                    Our Mission
                </Link>
                {isHomePage ? (
                    <>
                        <Link className="default-nav-item" to="/post-a-job">
                            For Employers
                        </Link>
                    </>
                ) : (
                    <>
                        <a
                            className="default-nav-item"
                            href="mailto:human@awnku.com"
                        >
                            Contact Us
                        </a>
                    </>
                )}
            </div>
        </div>
    );
}

export default Header;
