import { Helmet } from "react-helmet";

const GoogleAnalytics = () => {
    return (
        <Helmet>
            <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-NNGTKWRPKY"
            ></script>
            <script>
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){window.dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'G-NNGTKWRPKY');
                `}
            </script>
        </Helmet>
    );
};

export default GoogleAnalytics;
