/**
* This file was @generated using pocketbase-typegen
*/

export enum Collections {
	Jobs = "jobs",
	JobsCreatedBackup = "jobs_created_backup",
	Users = "users",
}

// Alias types for improved usability
export type IsoDateString = string
export type RecordIdString = string
export type HTMLString = string

// System fields
export type BaseSystemFields<T = never> = {
	id: RecordIdString
	created: IsoDateString
	updated: IsoDateString
	collectionId: string
	collectionName: Collections
	expand?: T
}

export type AuthSystemFields<T = never> = {
	email: string
	emailVisibility: boolean
	username: string
	verified: boolean
} & BaseSystemFields<T>

// Record types for each collection

export enum JobsCountryOptions {
	"🌎 Worldwide" = "🌎 Worldwide",
	"🇺🇸 United States" = "🇺🇸 United States",
	"🇨🇦 Canada" = "🇨🇦 Canada",
	"🇬🇧 United Kingdom" = "🇬🇧 United Kingdom",
	"🇦🇺 Australia" = "🇦🇺 Australia",
	"🇳🇿 New Zealand" = "🇳🇿 New Zealand",
	"🇮🇳 India" = "🇮🇳 India",
	"🇵🇹 Portugal" = "🇵🇹 Portugal",
	"🇩🇪 Germany" = "🇩🇪 Germany",
	"🇳🇱 Netherlands" = "🇳🇱 Netherlands",
	"🇨🇳 China" = "🇨🇳 China",
	"🇸🇬 Singapore" = "🇸🇬 Singapore",
	"🇫🇷 France" = "🇫🇷 France",
	"🇭🇰 Hong Kong" = "🇭🇰 Hong Kong",
	"🇹🇼 Taiwan" = "🇹🇼 Taiwan",
	"🇧🇷 Brazil" = "🇧🇷 Brazil",
	"🇬🇷 Greece" = "🇬🇷 Greece",
	"🇦🇪 United Arab Emirates" = "🇦🇪 United Arab Emirates",
	"🇸🇪 Sweden" = "🇸🇪 Sweden",
	"🇮🇹 Italy" = "🇮🇹 Italy",
	"🇵🇱 Poland" = "🇵🇱 Poland",
	"🇪🇸 Spain" = "🇪🇸 Spain",
	"🇲🇽 Mexico" = "🇲🇽 Mexico",
	"🇺🇦 Ukraine" = "🇺🇦 Ukraine",
	"🇯🇵 Japan" = "🇯🇵 Japan",
	"🇻🇳 Vietnam" = "🇻🇳 Vietnam",
	"🇹🇭 Thailand" = "🇹🇭 Thailand",
	"🇨🇿 Czechia" = "🇨🇿 Czechia",
	"🇷🇺 Russia" = "🇷🇺 Russia",
	"🇮🇱 Israel" = "🇮🇱 Israel",
	"🇫🇮 Finland" = "🇫🇮 Finland",
	"🇮🇩 Indonesia" = "🇮🇩 Indonesia",
	"🇨🇭 Switzerland" = "🇨🇭 Switzerland",
	"🇰🇷 South Korea" = "🇰🇷 South Korea",
	"🇵🇰 Pakistan" = "🇵🇰 Pakistan",
	"🇧🇩 Bangladesh" = "🇧🇩 Bangladesh",
	"🇳🇵 Nepal" = "🇳🇵 Nepal",
	"⛰️ North America" = "⛰️ North America",
	"💃 Latin America" = "💃 Latin America",
	"🇪🇺 Europe" = "🇪🇺 Europe",
	"🦁 Africa" = "🦁 Africa",
	"🕌 Middle East" = "🕌 Middle East",
	"⛩ Asia" = "⛩ Asia",
	"🌊 Oceania" = "🌊 Oceania",
	"🇦🇱 Albania" = "🇦🇱 Albania",
	"🇩🇿 Algeria" = "🇩🇿 Algeria",
	"🇦🇩 Andorra" = "🇦🇩 Andorra",
	"🇦🇴 Angola" = "🇦🇴 Angola",
	"🇦🇮 Anguilla" = "🇦🇮 Anguilla",
	"🇦🇷 Argentina" = "🇦🇷 Argentina",
	"🇦🇲 Armenia" = "🇦🇲 Armenia",
	"🇦🇹 Austria" = "🇦🇹 Austria",
	"🇦🇿 Azerbaijan" = "🇦🇿 Azerbaijan",
	"🇧🇸 Bahamas" = "🇧🇸 Bahamas",
	"🇧🇭 Bahrain" = "🇧🇭 Bahrain",
	"🇧🇧 Barbados" = "🇧🇧 Barbados",
	"🇧🇾 Belarus" = "🇧🇾 Belarus",
	"🇧🇪 Belgium" = "🇧🇪 Belgium",
	"🇧🇿 Belize" = "🇧🇿 Belize",
	"🇧🇯 Benin" = "🇧🇯 Benin",
	"🇧🇲 Bermuda" = "🇧🇲 Bermuda",
	"🇧🇹 Bhutan" = "🇧🇹 Bhutan",
	"🇧🇴 Bolivia" = "🇧🇴 Bolivia",
	"🇧🇦 Bosnia and Herzegovina" = "🇧🇦 Bosnia and Herzegovina",
	"🇧🇼 Botswana" = "🇧🇼 Botswana",
	"🇧🇳 Brunei" = "🇧🇳 Brunei",
	"🇧🇬 Bulgaria" = "🇧🇬 Bulgaria",
	"🇧🇮 Burundi" = "🇧🇮 Burundi",
	"🇰🇭 Cambodia" = "🇰🇭 Cambodia",
	"🇨🇲 Cameroon" = "🇨🇲 Cameroon",
	"🇨🇫 Central African Republic" = "🇨🇫 Central African Republic",
	"🇹🇩 Chad" = "🇹🇩 Chad",
	"🇨🇱 Chile" = "🇨🇱 Chile",
	"🇨🇴 Colombia" = "🇨🇴 Colombia",
	"🇰🇲 Comoros" = "🇰🇲 Comoros",
	"🇨🇷 Costa Rica" = "🇨🇷 Costa Rica",
	"🇭🇷 Croatia" = "🇭🇷 Croatia",
	"🇨🇺 Cuba" = "🇨🇺 Cuba",
	"🇨🇼 Curacao" = "🇨🇼 Curacao",
	"🇨🇾 Cyprus" = "🇨🇾 Cyprus",
	"🇨🇩 Democratic Republic of the Congo" = "🇨🇩 Democratic Republic of the Congo",
	"🇩🇰 Denmark" = "🇩🇰 Denmark",
	"🇩🇯 Djibouti" = "🇩🇯 Djibouti",
	"🇩🇲 Dominica" = "🇩🇲 Dominica",
	"🇩🇴 Dominican Republic" = "🇩🇴 Dominican Republic",
	"🇪🇨 Ecuador" = "🇪🇨 Ecuador",
	"🇪🇬 Egypt" = "🇪🇬 Egypt",
	"🇸🇻 El Salvador" = "🇸🇻 El Salvador",
	"🇬🇶 Equatorial Guinea" = "🇬🇶 Equatorial Guinea",
	"🇪🇷 Eritrea" = "🇪🇷 Eritrea",
	"🇪🇪 Estonia" = "🇪🇪 Estonia",
	"🇪🇹 Ethiopia" = "🇪🇹 Ethiopia",
	"🇬🇫 French Guiana" = "🇬🇫 French Guiana",
	"🇵🇫 French Polynesia" = "🇵🇫 French Polynesia",
	"🇹🇫 French Southern Territories" = "🇹🇫 French Southern Territories",
	"🇬🇦 Gabon" = "🇬🇦 Gabon",
	"🇬🇲 Gambia" = "🇬🇲 Gambia",
	"🇬🇪 Georgia" = "🇬🇪 Georgia",
	"🇬🇭 Ghana" = "🇬🇭 Ghana",
	"🇬🇮 Gibraltar" = "🇬🇮 Gibraltar",
	"🇬🇱 Greenland" = "🇬🇱 Greenland",
	"🇬🇩 Grenada" = "🇬🇩 Grenada",
	"🇬🇵 Guadeloupe" = "🇬🇵 Guadeloupe",
	"🇬🇹 Guatemala" = "🇬🇹 Guatemala",
	"🇬🇬 Guernsey" = "🇬🇬 Guernsey",
	"🇬🇾 Guyana" = "🇬🇾 Guyana",
	"🇭🇹 Haiti" = "🇭🇹 Haiti",
	"🇭🇳 Honduras" = "🇭🇳 Honduras",
	"🇭🇺 Hungary" = "🇭🇺 Hungary",
	"🇮🇸 Iceland" = "🇮🇸 Iceland",
	"🇮🇪 Ireland" = "🇮🇪 Ireland",
	"🇨🇮 Ivory Coast" = "🇨🇮 Ivory Coast",
	"🇯🇲 Jamaica" = "🇯🇲 Jamaica",
	"🇯🇴 Jordan" = "🇯🇴 Jordan",
	"🇰🇿 Kazakhstan" = "🇰🇿 Kazakhstan",
	"🇰🇪 Kenya" = "🇰🇪 Kenya",
	"🇰🇮 Kiribati" = "🇰🇮 Kiribati",
	"🇽🇰 Kosovo" = "🇽🇰 Kosovo",
	"🇰🇼 Kuwait" = "🇰🇼 Kuwait",
	"🇰🇬 Kyrgyzstan" = "🇰🇬 Kyrgyzstan",
	"🇱🇦 Laos" = "🇱🇦 Laos",
	"🇱🇻 Latvia" = "🇱🇻 Latvia",
	"🇱🇧 Lebanon" = "🇱🇧 Lebanon",
	"🇱🇸 Lesotho" = "🇱🇸 Lesotho",
	"🇱🇷 Liberia" = "🇱🇷 Liberia",
	"🇱🇾 Libya" = "🇱🇾 Libya",
	"🇱🇮 Liechtenstein" = "🇱🇮 Liechtenstein",
	"🇱🇹 Lithuania" = "🇱🇹 Lithuania",
	"🇱🇺 Luxembourg" = "🇱🇺 Luxembourg",
	"🇲🇴 Macao" = "🇲🇴 Macao",
	"🇲🇬 Madagascar" = "🇲🇬 Madagascar",
	"🇲🇼 Malawi" = "🇲🇼 Malawi",
	"🇲🇾 Malaysia" = "🇲🇾 Malaysia",
	"🇲🇻 Maldives" = "🇲🇻 Maldives",
	"🇲🇱 Mali" = "🇲🇱 Mali",
	"🇲🇹 Malta" = "🇲🇹 Malta",
	"🇲🇭 Marshall Islands" = "🇲🇭 Marshall Islands",
	"🇲🇶 Martinique" = "🇲🇶 Martinique",
	"🇲🇷 Mauritania" = "🇲🇷 Mauritania",
	"🇲🇺 Mauritius" = "🇲🇺 Mauritius",
	"🇾🇹 Mayotte" = "🇾🇹 Mayotte",
	"🇲🇩 Moldova" = "🇲🇩 Moldova",
	"🇲🇨 Monaco" = "🇲🇨 Monaco",
	"🇲🇳 Mongolia" = "🇲🇳 Mongolia",
	"🇲🇪 Montenegro" = "🇲🇪 Montenegro",
	"🇲🇸 Montserrat" = "🇲🇸 Montserrat",
	"🇲🇦 Morocco" = "🇲🇦 Morocco",
	"🇲🇿 Mozambique" = "🇲🇿 Mozambique",
	"🇲🇲 Myanmar" = "🇲🇲 Myanmar",
	"🇳🇦 Namibia" = "🇳🇦 Namibia",
	"🇳🇷 Nauru" = "🇳🇷 Nauru",
	"🇳🇨 New Caledonia" = "🇳🇨 New Caledonia",
	"🇳🇮 Nicaragua" = "🇳🇮 Nicaragua",
	"🇳🇪 Niger" = "🇳🇪 Niger",
	"🇳🇬 Nigeria" = "🇳🇬 Nigeria",
	"🇳🇺 Niue" = "🇳🇺 Niue",
	"🇰🇵 North Korea" = "🇰🇵 North Korea",
	"🇲🇰 North Macedonia" = "🇲🇰 North Macedonia",
	"🇳🇴 Norway" = "🇳🇴 Norway",
	"🇴🇲 Oman" = "🇴🇲 Oman",
	"🇵🇼 Palau" = "🇵🇼 Palau",
	"🇵🇦 Panama" = "🇵🇦 Panama",
	"🇵🇬 Papua New Guinea" = "🇵🇬 Papua New Guinea",
	"🇵🇾 Paraguay" = "🇵🇾 Paraguay",
	"🇵🇪 Peru" = "🇵🇪 Peru",
	"🇵🇭 Philippines" = "🇵🇭 Philippines",
	"🇵🇳 Pitcairn" = "🇵🇳 Pitcairn",
	"🇵🇷 Puerto Rico" = "🇵🇷 Puerto Rico",
	"🇶🇦 Qatar" = "🇶🇦 Qatar",
	"🇷🇪 Reunion" = "🇷🇪 Reunion",
	"🇷🇴 Romania" = "🇷🇴 Romania",
	"🇼🇸 Samoa" = "🇼🇸 Samoa",
	"🇸🇦 Saudi Arabia" = "🇸🇦 Saudi Arabia",
	"🇸🇳 Senegal" = "🇸🇳 Senegal",
	"🇷🇸 Serbia" = "🇷🇸 Serbia",
	"🇸🇱 Sierra Leone" = "🇸🇱 Sierra Leone",
	"🇸🇰 Slovakia" = "🇸🇰 Slovakia",
	"🇸🇮 Slovenia" = "🇸🇮 Slovenia",
	"🇸🇴 Somalia" = "🇸🇴 Somalia",
	"🇿🇦 South Africa" = "🇿🇦 South Africa",
	"🇸🇸 South Sudan" = "🇸🇸 South Sudan",
	"🇱🇰 Sri Lanka" = "🇱🇰 Sri Lanka",
	"🇸🇩 Sudan" = "🇸🇩 Sudan",
	"🇸🇷 Suriname" = "🇸🇷 Suriname",
	"🇸🇾 Syria" = "🇸🇾 Syria",
	"🇹🇯 Tajikistan" = "🇹🇯 Tajikistan",
	"🇹🇿 Tanzania" = "🇹🇿 Tanzania",
	"🇹🇱 Timor Leste" = "🇹🇱 Timor Leste",
	"🇹🇰 Tokelau" = "🇹🇰 Tokelau",
	"🇹🇴 Tonga" = "🇹🇴 Tonga",
	"🇹🇹 Trinidad and Tobago" = "🇹🇹 Trinidad and Tobago",
	"🇹🇳 Tunisia" = "🇹🇳 Tunisia",
	"🇹🇷 Turkey" = "🇹🇷 Turkey",
	"🇹🇲 Turkmenistan" = "🇹🇲 Turkmenistan",
	"🇹🇻 Tuvalu" = "🇹🇻 Tuvalu",
	"🇺🇬 Uganda" = "🇺🇬 Uganda",
	"🇺🇾 Uruguay" = "🇺🇾 Uruguay",
	"🇺🇿 Uzbekistan" = "🇺🇿 Uzbekistan",
	"🇻🇪 Venezuela" = "🇻🇪 Venezuela",
	"🇪🇭 Western Sahara" = "🇪🇭 Western Sahara",
	"🇾🇪 Yemen" = "🇾🇪 Yemen",
	"🇿🇲 Zambia" = "🇿🇲 Zambia",
	"🇿🇼 Zimbabwe" = "🇿🇼 Zimbabwe",
}

export enum JobsRemoteOptions {
	"💻 Remote" = "💻 Remote",
}

export enum JobsTagsOptions {
	"🤖 Machine Learning" = "🤖 Machine Learning",
	"🧠 AI" = "🧠 AI",
	"🐍 Python" = "🐍 Python",
	"💬 NLP" = "💬 NLP",
	"🔢 SQL" = "🔢 SQL",
	"📊 Data Science" = "📊 Data Science",
	"🔥 PyTorch" = "🔥 PyTorch",
	"📊 R" = "📊 R",
	"🔍 Research" = "🔍 Research",
	"💻 Software" = "💻 Software",
	"👴 Senior" = "👴 Senior",
	"🌐 AWS" = "🌐 AWS",
	"🎆 Spark" = "🎆 Spark",
	"🤖 Tensorflow" = "🤖 Tensorflow",
	"🧠 Keras" = "🧠 Keras",
	"🧠 Deep Learning" = "🧠 Deep Learning",
	"➕ Mathematics" = "➕ Mathematics",
	"☕ Java" = "☕ Java",
	"🐼 Pandas" = "🐼 Pandas",
	"🔒 Security" = "🔒 Security",
	"🔄 ETL" = "🔄 ETL",
	"🎬 Director" = "🎬 Director",
	"🔗 APIs" = "🔗 APIs",
	"💨 Airflow" = "💨 Airflow",
	"🐘 Hadoop" = "🐘 Hadoop",
	"🎯 KPIs" = "🎯 KPIs",
	"🎯 Scala" = "🎯 Scala",
	"🐳 Kubernetes" = "🐳 Kubernetes",
	"💬 ChatGPT" = "💬 ChatGPT",
	"📺 Ads" = "📺 Ads",
	"🤖 MLOps" = "🤖 MLOps",
	"🧪 Physics" = "🧪 Physics",
	"🧠 AGI" = "🧠 AGI",
	"🔵 NoSQL" = "🔵 NoSQL",
	"📤 Kafka" = "📤 Kafka",
	"💻 Developer" = "💻 Developer",
	"📊 Big Data" = "📊 Big Data",
	"💰 Finance" = "💰 Finance",
	"🐝 Hive" = "🐝 Hive",
	"🎓 Education" = "🎓 Education",
	"🦅 Apache" = "🦅 Apache",
	"🔷 Tableau" = "🔷 Tableau",
	"🔥 Databricks" = "🔥 Databricks",
	"🐦 Biology" = "🐦 Biology",
	"🐳 Docker" = "🐳 Docker",
	"🐧 Linux" = "🐧 Linux",
	"🎓 LLMs" = "🎓 LLMs",
	"📣 Marketing" = "📣 Marketing",
	"📎 Excel" = "📎 Excel",
	"🏥 Healthcare" = "🏥 Healthcare",
	"🌍 Terraform" = "🌍 Terraform",
	"🔀 Git" = "🔀 Git",
	"📲 JavaScript" = "📲 JavaScript",
	"💻 CUDA" = "💻 CUDA",
	"📊 Salesforce" = "📊 Salesforce",
	"📱 Android" = "📱 Android",
	"💻 DevOps" = "💻 DevOps",
	"📄 Athena" = "📄 Athena",
	"📚 Transformers" = "📚 Transformers",
	"📓 Jupyter" = "📓 Jupyter",
	"🔄 REST" = "🔄 REST",
	"🧮 SciPy" = "🧮 SciPy",
	"📝 NLG" = "📝 NLG",
	"📚 NLTK" = "📚 NLTK",
	"🔎 QA" = "🔎 QA",
	"🦀 Rust" = "🦀 Rust",
	"📱 Mobile" = "📱 Mobile",
	"🔍 Analyst" = "🔍 Analyst",
	"📊 Visualization" = "📊 Visualization",
	"🧪 Testing" = "🧪 Testing",
	"📝 Statistics" = "📝 Statistics",
	"👂 ASR" = "👂 ASR",
	"📊 BigQuery" = "📊 BigQuery",
	"🔢 Cassandra" = "🔢 Cassandra",
	"🔄 Flink" = "🔄 Flink",
	"💻 GPU" = "💻 GPU",
	"🎨 AI Art" = "🎨 AI Art",
	"🐏 Lambda" = "🐏 Lambda",
	"📄 JSON" = "📄 JSON",
	"🔧 Jenkins" = "🔧 Jenkins",
	"💰 Sales" = "💰 Sales",
	"👶 Junior" = "👶 Junior",
	"🐷 Pig" = "🐷 Pig",
	"📊 Data Analysis" = "📊 Data Analysis",
	"📈 Data Analytics" = "📈 Data Analytics",
	"🔄 Agile" = "🔄 Agile",
	"🔀 Streaming" = "🔀 Streaming",
	"🔵 Clustering" = "🔵 Clustering",
	"🔶 Oracle" = "🔶 Oracle",
	"🔬 Neuroscience" = "🔬 Neuroscience",
	"💰 Economics" = "💰 Economics",
	"🔍 Elasticsearch" = "🔍 Elasticsearch",
	"🔄 ELT" = "🔄 ELT",
	"📊 Kibana" = "📊 Kibana",
	"🔢 MySQL" = "🔢 MySQL",
	"🔢 PostgreSQL" = "🔢 PostgreSQL",
	"📊 Power BI" = "📊 Power BI",
	"📐 Linear Algebra" = "📐 Linear Algebra",
	"🌊 MLFlow" = "🌊 MLFlow",
	"🍃 MongoDB" = "🍃 MongoDB",
	"🤝 HCI" = "🤝 HCI",
	"🔮 AR" = "🔮 AR",
	"💰 FinTech" = "💰 FinTech",
	"🌐 MXNet" = "🌐 MXNet",
	"🔺 Theano" = "🔺 Theano",
	"📐 CAD" = "📐 CAD",
	"📈 Matlab" = "📈 Matlab",
	"🎯 Bayesian" = "🎯 Bayesian",
	"🔎 Marketmuse" = "🔎 Marketmuse",
	"📊 Jira" = "📊 Jira",
	"🍶 Flask" = "🍶 Flask",
	"🚀 Kubeflow" = "🚀 Kubeflow",
	"🐍 Django" = "🐍 Django",
	"🦦 Golang" = "🦦 Golang",
	"💬 Conversational AI" = "💬 Conversational AI",
	"📑 XML" = "📑 XML",
	"📞 Customer Support" = "📞 Customer Support",
	"🧠 NLU" = "🧠 NLU",
	"🎮 Reinforcement Learning" = "🎮 Reinforcement Learning",
	"💼 Kotlin" = "💼 Kotlin",
	"📱 Swift" = "📱 Swift",
	"🎯 Dart" = "🎯 Dart",
	"🎥 Video" = "🎥 Video",
	"💼 Accounting" = "💼 Accounting",
	"🎨 CSS" = "🎨 CSS",
	"💻 HTML" = "💻 HTML",
	"🔍 SEO" = "🔍 SEO",
	"📱 Social Media" = "📱 Social Media",
	"📱 Instagram" = "📱 Instagram",
	"🤖 Robotics" = "🤖 Robotics",
	"🚰 Pipelines" = "🚰 Pipelines",
	"🌐 Distributed Systems" = "🌐 Distributed Systems",
	"🗃️ RDBMS" = "🗃️ RDBMS",
	"❄️ Snowflake" = "❄️ Snowflake",
	"☁️ Azure" = "☁️ Azure",
	"☁️ GCP" = "☁️ GCP",
	"🔍 Looker" = "🔍 Looker",
	"🔮 Qlik" = "🔮 Qlik",
	"🔄 CI" = "🔄 CI",
	"🔄 CD" = "🔄 CD",
	"📋 Kanban" = "📋 Kanban",
	"🏉 Scrum" = "🏉 Scrum",
	"🧪 TDD" = "🧪 TDD",
	"🔬 R&D" = "🔬 R&D",
	"👁️ UI" = "👁️ UI",
	"👁️ UX" = "👁️ UX",
	"🏷️ Classification" = "🏷️ Classification",
	"🏦 Banking" = "🏦 Banking",
	"➕ C++" = "➕ C++",
	"🔄 Informatica" = "🔄 Informatica",
	"🔃 Talend" = "🔃 Talend",
	"💾 Teradata" = "💾 Teradata",
	"⛓️ Blockchain" = "⛓️ Blockchain",
	"⛏️ Data Mining" = "⛏️ Data Mining",
	"🔄 SSIS" = "🔄 SSIS",
	"🔢 T-SQL" = "🔢 T-SQL",
	"🧠 BCI" = "🧠 BCI",
	"🧬 Neurotech" = "🧬 Neurotech",
	"🔭 Physics" = "🔭 Physics",
	"⚗️ Chemistry" = "⚗️ Chemistry",
	"👥 Modelling" = "👥 Modelling",
	"🔊 TTS" = "🔊 TTS",
	"🎨 Data Studio" = "🎨 Data Studio",
	"🔬 Microservices" = "🔬 Microservices",
	"#️⃣ NumPy" = "#️⃣ NumPy",
	"💼 Consulting" = "💼 Consulting",
	"👥 Bitbucket" = "👥 Bitbucket",
	"💳 Banking" = "💳 Banking",
	"💾 DynamoDB" = "💾 DynamoDB",
	"👁️ Looker" = "👁️ Looker",
	"🔬 Julia" = "🔬 Julia",
	"💾 Bigtable" = "💾 Bigtable",
	"🔢 Hbase" = "🔢 Hbase",
	"🌐 MPP" = "🌐 MPP",
	"🔄 Oozie" = "🔄 Oozie",
	"🔧 Ansible" = "🔧 Ansible",
	"🖥️ Bash" = "🖥️ Bash",
	"🎲 Probability" = "🎲 Probability",
	"💾 MS SQL" = "💾 MS SQL",
	"🗄️ DB2" = "🗄️ DB2",
	"🔺 Angular" = "🔺 Angular",
	"🗺️ Map Reduce" = "🗺️ Map Reduce",
	"🛠️ Maven" = "🛠️ Maven",
	"🗂️ HDFS" = "🗂️ HDFS",
	"🖼️ Image Processing" = "🖼️ Image Processing",
	"👀 Object Recognition" = "👀 Object Recognition",
	"🔍 Feature Extraction" = "🔍 Feature Extraction",
	"📜 OCR" = "📜 OCR",
	"🕹️ 3D Modeling" = "🕹️ 3D Modeling",
	"🏥 Medical Imaging" = "🏥 Medical Imaging",
	"🚗 Autonomous Driving" = "🚗 Autonomous Driving",
	"🕶️ VR" = "🕶️ VR",
	"🖼️🔀 GANs" = "🖼️🔀 GANs",
	"🏗️ 3D Reconstruction" = "🏗️ 3D Reconstruction",
	"🛰️ Lidar" = "🛰️ Lidar",
	"🗺️ SLAM" = "🗺️ SLAM",
	"🎯 Model Training" = "🎯 Model Training",
	"👁️ OpenCV" = "👁️ OpenCV",
	"🔬 Material Science" = "🔬 Material Science",
	"🌐 Diffusion Models" = "🌐 Diffusion Models",
	"☁️ Cloud Computing" = "☁️ Cloud Computing",
	"☕ Caffe" = "☕ Caffe",
	"🗣️ GPT" = "🗣️ GPT",
	"🌐 Open Source" = "🌐 Open Source",
	"🧬 Genetics" = "🧬 Genetics",
	"🌙 Lua" = "🌙 Lua",
	"🌌 Astrophysics" = "🌌 Astrophysics",
	"🔠 TypeScript" = "🔠 TypeScript",
	"🌐 Web Developer" = "🌐 Web Developer",
	"💻 Frontend" = "💻 Frontend",
	"🖥️ Backend" = "🖥️ Backend",
	"🌐 Full Stack" = "🌐 Full Stack",
	"🌐 Stable Diffusion" = "🌐 Stable Diffusion",
	"🔗 GraphQL" = "🔗 GraphQL",
	"💻 Perl" = "💻 Perl",
	"☁️ Google Cloud" = "☁️ Google Cloud",
	"📊 Grafana" = "📊 Grafana",
	"📚 Scikit Learn" = "📚 Scikit Learn",
	"💰 Crypto" = "💰 Crypto",
	"🕸️ Web3" = "🕸️ Web3",
	"💻 Prompt Engineering" = "💻 Prompt Engineering",
	"🎨 DALL-E" = "🎨 DALL-E",
	"🚀 Midjourney" = "🚀 Midjourney",
	"💻 OOP" = "💻 OOP",
	"🏛️ Architecture" = "🏛️ Architecture",
	"🔌 Circuit Design" = "🔌 Circuit Design",
	"📊 D3" = "📊 D3",
	"⚛️ React" = "⚛️ React",
	"🔺 Vue" = "🔺 Vue",
	"🔬 Prototyping" = "🔬 Prototyping",
	"☁️ EC2" = "☁️ EC2",
	"🛍️ E-Commerce" = "🛍️ E-Commerce",
	"🔄 FiveTran" = "🔄 FiveTran",
	"🔬 Scrum" = "🔬 Scrum",
	"🌱 Node" = "🌱 Node",
	"👩‍🏫 Teaching" = "👩‍🏫 Teaching",
	"📈 Predictive Modeling" = "📈 Predictive Modeling",
	"🛠️ Dagster" = "🛠️ Dagster",
	"👩‍💼 Manager" = "👩‍💼 Manager",
	"🎖️ Leadership" = "🎖️ Leadership",
	"🖥️ Unix" = "🖥️ Unix",
	"📁 Parquet" = "📁 Parquet",
	"🤗 HuggingFace" = "🤗 HuggingFace",
	"💱 DeFi" = "💱 DeFi",
	"🔏 Solidity" = "🔏 Solidity",
	"🖥️ Microsoft" = "🖥️ Microsoft",
	"👩‍💻 Technical Support" = "👩‍💻 Technical Support",
	"⏱️ SLAs" = "⏱️ SLAs",
	"📊 Market Research" = "📊 Market Research",
	"💻 ES6" = "💻 ES6",
	"🎲 Monte Carlo" = "🎲 Monte Carlo",
	"👩‍💼 Executive" = "👩‍💼 Executive",
	"🔒 Data Governance" = "🔒 Data Governance",
	"🤖 Explainable AI" = "🤖 Explainable AI",
	"🕸️ Graph Databases" = "🕸️ Graph Databases",
	"🎲 Probalistic Programming" = "🎲 Probalistic Programming",
	"🕰️ Time Series" = "🕰️ Time Series",
	"⚛️ Quantum" = "⚛️ Quantum",
	"🐘 Mahout" = "🐘 Mahout",
	"💎 Ruby" = "💎 Ruby",
	"👩‍🔧 Engineer" = "👩‍🔧 Engineer",
	"💾 Sys Admin" = "💾 Sys Admin",
	"🍔 Full Stack" = "🍔 Full Stack",
	"⚙️ Ops" = "⚙️ Ops",
	"🔒 InfoSec" = "🔒 InfoSec",
	"✍️ Content Writing" = "✍️ Content Writing",
	"🖋️ Copy Writing" = "🖋️ Copy Writing",
	"☁️ SaaS" = "☁️ SaaS",
	"✅ Quality Assurance" = "✅ Quality Assurance",
	"🛍️ Ecommerce" = "🛍️ Ecommerce",
	"⚖️ Legal" = "⚖️ Legal",
	"📝 Admin" = "📝 Admin",
	"🖥️ PHP" = "🖥️ PHP",
	"🔧 Serverless" = "🔧 Serverless",
	"📱 iOS" = "📱 iOS",
	"🖥️ C" = "🖥️ C",
	"💻 C#" = "💻 C#",
	"🤖 Virtual Assistant" = "🤖 Virtual Assistant",
	"🎵 Music" = "🎵 Music",
	"🛍️ Shopify" = "🛍️ Shopify",
	"📝 Wordpress" = "📝 Wordpress",
	"🖥️ Drupal" = "🖥️ Drupal",
	"💰 Payroll" = "💰 Payroll",
	"👩‍🎓 Internship" = "👩‍🎓 Internship",
	"📱 Objective C" = "📱 Objective C",
	"🖥️ HDL" = "🖥️ HDL",
	"🔬 Semiconductors" = "🔬 Semiconductors",
	"🖥️ Hardware" = "🖥️ Hardware",
	"🔌 Electronics" = "🔌 Electronics",
	"📱 TikTok" = "📱 TikTok",
	"📸 Influencer" = "📸 Influencer",
	"👁️ Computer Vision" = "👁️ Computer Vision",
}

export enum JobsPostTypeOptions {
	"featured" = "featured",
	"aggregated" = "aggregated",
}
export type JobsRecord = {
	updated_ats: IsoDateString
	logo?: string
	title?: string
	company?: string
	country?: JobsCountryOptions[]
	remote?: JobsRemoteOptions
	tags?: JobsTagsOptions[]
	location?: string
	min_salary?: string
	max_salary?: string
	apply_link?: string
	id_ats?: string
	commitment?: string
	region?: string
	company_url?: string
	post_type?: JobsPostTypeOptions
	paid?: boolean
}

export enum JobsCreatedBackupCountryOptions {
	"🌎 Worldwide" = "🌎 Worldwide",
	"🇺🇸 United States" = "🇺🇸 United States",
	"🇨🇦 Canada" = "🇨🇦 Canada",
	"🇬🇧 United Kingdom" = "🇬🇧 United Kingdom",
	"🇦🇺 Australia" = "🇦🇺 Australia",
	"🇳🇿 New Zealand" = "🇳🇿 New Zealand",
	"🇮🇳 India" = "🇮🇳 India",
	"🇵🇹 Portugal" = "🇵🇹 Portugal",
	"🇩🇪 Germany" = "🇩🇪 Germany",
	"🇳🇱 Netherlands" = "🇳🇱 Netherlands",
	"🇸🇬 Singapore" = "🇸🇬 Singapore",
	"🇫🇷 France" = "🇫🇷 France",
	"🇭🇰 Hong Kong" = "🇭🇰 Hong Kong",
	"🇹🇼 Taiwan" = "🇹🇼 Taiwan",
	"🇨🇳 China" = "🇨🇳 China",
	"🇧🇷 Brazil" = "🇧🇷 Brazil",
	"🇬🇷 Greece" = "🇬🇷 Greece",
	"🇦🇪 United Arab Emirates" = "🇦🇪 United Arab Emirates",
	"🇸🇪 Sweden" = "🇸🇪 Sweden",
	"🇮🇹 Italy" = "🇮🇹 Italy",
	"🇵🇱 Poland" = "🇵🇱 Poland",
	"🇪🇸 Spain" = "🇪🇸 Spain",
	"🇲🇽 Mexico" = "🇲🇽 Mexico",
	"🇺🇦 Ukraine" = "🇺🇦 Ukraine",
	"🇯🇵 Japan" = "🇯🇵 Japan",
	"🇻🇳 Vietnam" = "🇻🇳 Vietnam",
	"🇹🇭 Thailand" = "🇹🇭 Thailand",
	"🇨🇿 Czechia" = "🇨🇿 Czechia",
	"🇷🇺 Russia" = "🇷🇺 Russia",
	"🇮🇱 Israel" = "🇮🇱 Israel",
	"🇫🇮 Finland" = "🇫🇮 Finland",
	"🇮🇩 Indonesia" = "🇮🇩 Indonesia",
	"🇨🇭 Switzerland" = "🇨🇭 Switzerland",
	"🇰🇷 South Korea" = "🇰🇷 South Korea",
	"🇵🇰 Pakistan" = "🇵🇰 Pakistan",
	"🇧🇩 Bangladesh" = "🇧🇩 Bangladesh",
	"🇳🇵 Nepal" = "🇳🇵 Nepal",
	"⛰️ North America" = "⛰️ North America",
	"💃 Latin America" = "💃 Latin America",
	"🇪🇺 Europe" = "🇪🇺 Europe",
	"🦁 Africa" = "🦁 Africa",
	"🕌 Middle East" = "🕌 Middle East",
	"⛩ Asia" = "⛩ Asia",
	"🌊 Oceania" = "🌊 Oceania",
	"🇦🇱 Albania" = "🇦🇱 Albania",
	"🇩🇿 Algeria" = "🇩🇿 Algeria",
	"🇦🇩 Andorra" = "🇦🇩 Andorra",
	"🇦🇴 Angola" = "🇦🇴 Angola",
	"🇦🇮 Anguilla" = "🇦🇮 Anguilla",
	"🇦🇷 Argentina" = "🇦🇷 Argentina",
	"🇦🇲 Armenia" = "🇦🇲 Armenia",
	"🇦🇹 Austria" = "🇦🇹 Austria",
	"🇦🇿 Azerbaijan" = "🇦🇿 Azerbaijan",
	"🇧🇸 Bahamas" = "🇧🇸 Bahamas",
	"🇧🇭 Bahrain" = "🇧🇭 Bahrain",
	"🇧🇧 Barbados" = "🇧🇧 Barbados",
	"🇧🇾 Belarus" = "🇧🇾 Belarus",
	"🇧🇪 Belgium" = "🇧🇪 Belgium",
	"🇧🇿 Belize" = "🇧🇿 Belize",
	"🇧🇯 Benin" = "🇧🇯 Benin",
	"🇧🇲 Bermuda" = "🇧🇲 Bermuda",
	"🇧🇹 Bhutan" = "🇧🇹 Bhutan",
	"🇧🇴 Bolivia" = "🇧🇴 Bolivia",
	"🇧🇦 Bosnia and Herzegovina" = "🇧🇦 Bosnia and Herzegovina",
	"🇧🇼 Botswana" = "🇧🇼 Botswana",
	"🇧🇳 Brunei" = "🇧🇳 Brunei",
	"🇧🇬 Bulgaria" = "🇧🇬 Bulgaria",
	"🇧🇮 Burundi" = "🇧🇮 Burundi",
	"🇰🇭 Cambodia" = "🇰🇭 Cambodia",
	"🇨🇲 Cameroon" = "🇨🇲 Cameroon",
	"🇨🇫 Central African Republic" = "🇨🇫 Central African Republic",
	"🇹🇩 Chad" = "🇹🇩 Chad",
	"🇨🇱 Chile" = "🇨🇱 Chile",
	"🇨🇴 Colombia" = "🇨🇴 Colombia",
	"🇰🇲 Comoros" = "🇰🇲 Comoros",
	"🇨🇷 Costa Rica" = "🇨🇷 Costa Rica",
	"🇭🇷 Croatia" = "🇭🇷 Croatia",
	"🇨🇺 Cuba" = "🇨🇺 Cuba",
	"🇨🇼 Curacao" = "🇨🇼 Curacao",
	"🇨🇾 Cyprus" = "🇨🇾 Cyprus",
	"🇨🇩 Democratic Republic of the Congo" = "🇨🇩 Democratic Republic of the Congo",
	"🇩🇰 Denmark" = "🇩🇰 Denmark",
	"🇩🇯 Djibouti" = "🇩🇯 Djibouti",
	"🇩🇲 Dominica" = "🇩🇲 Dominica",
	"🇩🇴 Dominican Republic" = "🇩🇴 Dominican Republic",
	"🇪🇨 Ecuador" = "🇪🇨 Ecuador",
	"🇪🇬 Egypt" = "🇪🇬 Egypt",
	"🇸🇻 El Salvador" = "🇸🇻 El Salvador",
	"🇬🇶 Equatorial Guinea" = "🇬🇶 Equatorial Guinea",
	"🇪🇷 Eritrea" = "🇪🇷 Eritrea",
	"🇪🇪 Estonia" = "🇪🇪 Estonia",
	"🇪🇹 Ethiopia" = "🇪🇹 Ethiopia",
	"🇬🇫 French Guiana" = "🇬🇫 French Guiana",
	"🇵🇫 French Polynesia" = "🇵🇫 French Polynesia",
	"🇹🇫 French Southern Territories" = "🇹🇫 French Southern Territories",
	"🇬🇦 Gabon" = "🇬🇦 Gabon",
	"🇬🇲 Gambia" = "🇬🇲 Gambia",
	"🇬🇪 Georgia" = "🇬🇪 Georgia",
	"🇬🇭 Ghana" = "🇬🇭 Ghana",
	"🇬🇮 Gibraltar" = "🇬🇮 Gibraltar",
	"🇬🇱 Greenland" = "🇬🇱 Greenland",
	"🇬🇩 Grenada" = "🇬🇩 Grenada",
	"🇬🇵 Guadeloupe" = "🇬🇵 Guadeloupe",
	"🇬🇹 Guatemala" = "🇬🇹 Guatemala",
	"🇬🇬 Guernsey" = "🇬🇬 Guernsey",
	"🇬🇾 Guyana" = "🇬🇾 Guyana",
	"🇭🇹 Haiti" = "🇭🇹 Haiti",
	"🇭🇳 Honduras" = "🇭🇳 Honduras",
	"🇭🇺 Hungary" = "🇭🇺 Hungary",
	"🇮🇸 Iceland" = "🇮🇸 Iceland",
	"🇮🇪 Ireland" = "🇮🇪 Ireland",
	"🇨🇮 Ivory Coast" = "🇨🇮 Ivory Coast",
	"🇯🇲 Jamaica" = "🇯🇲 Jamaica",
	"🇯🇴 Jordan" = "🇯🇴 Jordan",
	"🇰🇿 Kazakhstan" = "🇰🇿 Kazakhstan",
	"🇰🇪 Kenya" = "🇰🇪 Kenya",
	"🇰🇮 Kiribati" = "🇰🇮 Kiribati",
	"🇽🇰 Kosovo" = "🇽🇰 Kosovo",
	"🇰🇼 Kuwait" = "🇰🇼 Kuwait",
	"🇰🇬 Kyrgyzstan" = "🇰🇬 Kyrgyzstan",
	"🇱🇦 Laos" = "🇱🇦 Laos",
	"🇱🇻 Latvia" = "🇱🇻 Latvia",
	"🇱🇧 Lebanon" = "🇱🇧 Lebanon",
	"🇱🇸 Lesotho" = "🇱🇸 Lesotho",
	"🇱🇷 Liberia" = "🇱🇷 Liberia",
	"🇱🇾 Libya" = "🇱🇾 Libya",
	"🇱🇮 Liechtenstein" = "🇱🇮 Liechtenstein",
	"🇱🇹 Lithuania" = "🇱🇹 Lithuania",
	"🇱🇺 Luxembourg" = "🇱🇺 Luxembourg",
	"🇲🇴 Macao" = "🇲🇴 Macao",
	"🇲🇬 Madagascar" = "🇲🇬 Madagascar",
	"🇲🇼 Malawi" = "🇲🇼 Malawi",
	"🇲🇾 Malaysia" = "🇲🇾 Malaysia",
	"🇲🇻 Maldives" = "🇲🇻 Maldives",
	"🇲🇱 Mali" = "🇲🇱 Mali",
	"🇲🇹 Malta" = "🇲🇹 Malta",
	"🇲🇭 Marshall Islands" = "🇲🇭 Marshall Islands",
	"🇲🇶 Martinique" = "🇲🇶 Martinique",
	"🇲🇷 Mauritania" = "🇲🇷 Mauritania",
	"🇲🇺 Mauritius" = "🇲🇺 Mauritius",
	"🇾🇹 Mayotte" = "🇾🇹 Mayotte",
	"🇲🇩 Moldova" = "🇲🇩 Moldova",
	"🇲🇨 Monaco" = "🇲🇨 Monaco",
	"🇲🇳 Mongolia" = "🇲🇳 Mongolia",
	"🇲🇪 Montenegro" = "🇲🇪 Montenegro",
	"🇲🇸 Montserrat" = "🇲🇸 Montserrat",
	"🇲🇦 Morocco" = "🇲🇦 Morocco",
	"🇲🇿 Mozambique" = "🇲🇿 Mozambique",
	"🇲🇲 Myanmar" = "🇲🇲 Myanmar",
	"🇳🇦 Namibia" = "🇳🇦 Namibia",
	"🇳🇷 Nauru" = "🇳🇷 Nauru",
	"🇳🇨 New Caledonia" = "🇳🇨 New Caledonia",
	"🇳🇮 Nicaragua" = "🇳🇮 Nicaragua",
	"🇳🇪 Niger" = "🇳🇪 Niger",
	"🇳🇬 Nigeria" = "🇳🇬 Nigeria",
	"🇳🇺 Niue" = "🇳🇺 Niue",
	"🇰🇵 North Korea" = "🇰🇵 North Korea",
	"🇲🇰 North Macedonia" = "🇲🇰 North Macedonia",
	"🇳🇴 Norway" = "🇳🇴 Norway",
	"🇴🇲 Oman" = "🇴🇲 Oman",
	"🇵🇼 Palau" = "🇵🇼 Palau",
	"🇵🇦 Panama" = "🇵🇦 Panama",
	"🇵🇬 Papua New Guinea" = "🇵🇬 Papua New Guinea",
	"🇵🇾 Paraguay" = "🇵🇾 Paraguay",
	"🇵🇪 Peru" = "🇵🇪 Peru",
	"🇵🇭 Philippines" = "🇵🇭 Philippines",
	"🇵🇳 Pitcairn" = "🇵🇳 Pitcairn",
	"🇵🇷 Puerto Rico" = "🇵🇷 Puerto Rico",
	"🇶🇦 Qatar" = "🇶🇦 Qatar",
	"🇷🇪 Reunion" = "🇷🇪 Reunion",
	"🇷🇴 Romania" = "🇷🇴 Romania",
	"🇼🇸 Samoa" = "🇼🇸 Samoa",
	"🇸🇦 Saudi Arabia" = "🇸🇦 Saudi Arabia",
	"🇸🇳 Senegal" = "🇸🇳 Senegal",
	"🇷🇸 Serbia" = "🇷🇸 Serbia",
	"🇸🇱 Sierra Leone" = "🇸🇱 Sierra Leone",
	"🇸🇰 Slovakia" = "🇸🇰 Slovakia",
	"🇸🇮 Slovenia" = "🇸🇮 Slovenia",
	"🇸🇴 Somalia" = "🇸🇴 Somalia",
	"🇿🇦 South Africa" = "🇿🇦 South Africa",
	"🇸🇸 South Sudan" = "🇸🇸 South Sudan",
	"🇱🇰 Sri Lanka" = "🇱🇰 Sri Lanka",
	"🇸🇩 Sudan" = "🇸🇩 Sudan",
	"🇸🇷 Suriname" = "🇸🇷 Suriname",
	"🇸🇾 Syria" = "🇸🇾 Syria",
	"🇹🇯 Tajikistan" = "🇹🇯 Tajikistan",
	"🇹🇿 Tanzania" = "🇹🇿 Tanzania",
	"🇹🇱 Timor Leste" = "🇹🇱 Timor Leste",
	"🇹🇰 Tokelau" = "🇹🇰 Tokelau",
	"🇹🇴 Tonga" = "🇹🇴 Tonga",
	"🇹🇹 Trinidad and Tobago" = "🇹🇹 Trinidad and Tobago",
	"🇹🇳 Tunisia" = "🇹🇳 Tunisia",
	"🇹🇷 Turkey" = "🇹🇷 Turkey",
	"🇹🇲 Turkmenistan" = "🇹🇲 Turkmenistan",
	"🇹🇻 Tuvalu" = "🇹🇻 Tuvalu",
	"🇺🇬 Uganda" = "🇺🇬 Uganda",
	"🇺🇾 Uruguay" = "🇺🇾 Uruguay",
	"🇺🇿 Uzbekistan" = "🇺🇿 Uzbekistan",
	"🇻🇪 Venezuela" = "🇻🇪 Venezuela",
	"🇪🇭 Western Sahara" = "🇪🇭 Western Sahara",
	"🇾🇪 Yemen" = "🇾🇪 Yemen",
	"🇿🇲 Zambia" = "🇿🇲 Zambia",
	"🇿🇼 Zimbabwe" = "🇿🇼 Zimbabwe",
}

export enum JobsCreatedBackupRemoteOptions {
	"💻 Remote" = "💻 Remote",
}

export enum JobsCreatedBackupTagsOptions {
	"🤖 Machine Learning" = "🤖 Machine Learning",
	"🧠 AI" = "🧠 AI",
	"🐍 Python" = "🐍 Python",
	"💬 NLP" = "💬 NLP",
	"🔢 SQL" = "🔢 SQL",
	"📊 Data Science" = "📊 Data Science",
	"🔥 PyTorch" = "🔥 PyTorch",
	"📊 R" = "📊 R",
	"🔍 Research" = "🔍 Research",
	"💻 Software" = "💻 Software",
	"👴 Senior" = "👴 Senior",
	"🌐 AWS" = "🌐 AWS",
	"🎆 Spark" = "🎆 Spark",
	"🤖 Tensorflow" = "🤖 Tensorflow",
	"🧠 Keras" = "🧠 Keras",
	"🧠 Deep Learning" = "🧠 Deep Learning",
	"➕ Mathematics" = "➕ Mathematics",
	"☕ Java" = "☕ Java",
	"🐼 Pandas" = "🐼 Pandas",
	"🔒 Security" = "🔒 Security",
	"🔄 ETL" = "🔄 ETL",
	"🎬 Director" = "🎬 Director",
	"🔗 APIs" = "🔗 APIs",
	"💨 Airflow" = "💨 Airflow",
	"🐘 Hadoop" = "🐘 Hadoop",
	"🎯 KPIs" = "🎯 KPIs",
	"🎯 Scala" = "🎯 Scala",
	"🐳 Kubernetes" = "🐳 Kubernetes",
	"💬 ChatGPT" = "💬 ChatGPT",
	"📺 Ads" = "📺 Ads",
	"🤖 MLOps" = "🤖 MLOps",
	"🧪 Physics" = "🧪 Physics",
	"🧠 AGI" = "🧠 AGI",
	"🔵 NoSQL" = "🔵 NoSQL",
	"📤 Kafka" = "📤 Kafka",
	"💻 Developer" = "💻 Developer",
	"📊 Big Data" = "📊 Big Data",
	"💰 Finance" = "💰 Finance",
	"🐝 Hive" = "🐝 Hive",
	"🎓 Education" = "🎓 Education",
	"🦅 Apache" = "🦅 Apache",
	"🔷 Tableau" = "🔷 Tableau",
	"🔥 Databricks" = "🔥 Databricks",
	"🐦 Biology" = "🐦 Biology",
	"🐳 Docker" = "🐳 Docker",
	"🐧 Linux" = "🐧 Linux",
	"🎓 LLMs" = "🎓 LLMs",
	"📣 Marketing" = "📣 Marketing",
	"📎 Excel" = "📎 Excel",
	"🏥 Healthcare" = "🏥 Healthcare",
	"🌍 Terraform" = "🌍 Terraform",
	"🔀 Git" = "🔀 Git",
	"📲 JavaScript" = "📲 JavaScript",
	"💻 CUDA" = "💻 CUDA",
	"📊 Salesforce" = "📊 Salesforce",
	"📱 Android" = "📱 Android",
	"💻 DevOps" = "💻 DevOps",
	"📄 Athena" = "📄 Athena",
	"📚 Transformers" = "📚 Transformers",
	"📓 Jupyter" = "📓 Jupyter",
	"🔄 REST" = "🔄 REST",
	"🧮 SciPy" = "🧮 SciPy",
	"📝 NLG" = "📝 NLG",
	"📚 NLTK" = "📚 NLTK",
	"🔎 QA" = "🔎 QA",
	"🦀 Rust" = "🦀 Rust",
	"📱 Mobile" = "📱 Mobile",
	"🔍 Analyst" = "🔍 Analyst",
	"📊 Visualization" = "📊 Visualization",
	"🧪 Testing" = "🧪 Testing",
	"📝 Statistics" = "📝 Statistics",
	"👂 ASR" = "👂 ASR",
	"📊 BigQuery" = "📊 BigQuery",
	"🔢 Cassandra" = "🔢 Cassandra",
	"🔄 Flink" = "🔄 Flink",
	"💻 GPU" = "💻 GPU",
	"🎨 AI Art" = "🎨 AI Art",
	"🐏 Lambda" = "🐏 Lambda",
	"📄 JSON" = "📄 JSON",
	"🔧 Jenkins" = "🔧 Jenkins",
	"💰 Sales" = "💰 Sales",
	"👶 Junior" = "👶 Junior",
	"🐷 Pig" = "🐷 Pig",
	"📊 Data Analysis" = "📊 Data Analysis",
	"📈 Data Analytics" = "📈 Data Analytics",
	"🔄 Agile" = "🔄 Agile",
	"🔀 Streaming" = "🔀 Streaming",
	"🔵 Clustering" = "🔵 Clustering",
	"🔶 Oracle" = "🔶 Oracle",
	"🔬 Neuroscience" = "🔬 Neuroscience",
	"💰 Economics" = "💰 Economics",
	"🔍 Elasticsearch" = "🔍 Elasticsearch",
	"🔄 ELT" = "🔄 ELT",
	"📊 Kibana" = "📊 Kibana",
	"🔢 MySQL" = "🔢 MySQL",
	"🔢 PostgreSQL" = "🔢 PostgreSQL",
	"📊 Power BI" = "📊 Power BI",
	"📐 Linear Algebra" = "📐 Linear Algebra",
	"🌊 MLFlow" = "🌊 MLFlow",
	"🍃 MongoDB" = "🍃 MongoDB",
	"🤝 HCI" = "🤝 HCI",
	"🔮 AR" = "🔮 AR",
	"💰 FinTech" = "💰 FinTech",
	"🌐 MXNet" = "🌐 MXNet",
	"🔺 Theano" = "🔺 Theano",
	"📐 CAD" = "📐 CAD",
	"📈 Matlab" = "📈 Matlab",
	"🎯 Bayesian" = "🎯 Bayesian",
	"🔎 Marketmuse" = "🔎 Marketmuse",
	"📊 Jira" = "📊 Jira",
	"🍶 Flask" = "🍶 Flask",
	"🚀 Kubeflow" = "🚀 Kubeflow",
	"🐍 Django" = "🐍 Django",
	"🦦 Golang" = "🦦 Golang",
	"💬 Conversational AI" = "💬 Conversational AI",
	"📑 XML" = "📑 XML",
	"📞 Customer Support" = "📞 Customer Support",
	"🧠 NLU" = "🧠 NLU",
	"🎮 Reinforcement Learning" = "🎮 Reinforcement Learning",
	"💼 Kotlin" = "💼 Kotlin",
	"📱 Swift" = "📱 Swift",
	"🎯 Dart" = "🎯 Dart",
	"🎥 Video" = "🎥 Video",
	"💼 Accounting" = "💼 Accounting",
	"🎨 CSS" = "🎨 CSS",
	"💻 HTML" = "💻 HTML",
	"🔍 SEO" = "🔍 SEO",
	"📱 Social Media" = "📱 Social Media",
	"📱 Instagram" = "📱 Instagram",
	"🤖 Robotics" = "🤖 Robotics",
	"🚰 Pipelines" = "🚰 Pipelines",
	"🌐 Distributed Systems" = "🌐 Distributed Systems",
	"🗃️ RDBMS" = "🗃️ RDBMS",
	"❄️ Snowflake" = "❄️ Snowflake",
	"☁️ Azure" = "☁️ Azure",
	"☁️ GCP" = "☁️ GCP",
	"🔍 Looker" = "🔍 Looker",
	"🔮 Qlik" = "🔮 Qlik",
	"🔄 CI" = "🔄 CI",
	"🔄 CD" = "🔄 CD",
	"📋 Kanban" = "📋 Kanban",
	"🏉 Scrum" = "🏉 Scrum",
	"🧪 TDD" = "🧪 TDD",
	"🔬 R&D" = "🔬 R&D",
	"👁️ UI" = "👁️ UI",
	"👁️ UX" = "👁️ UX",
	"🏷️ Classification" = "🏷️ Classification",
	"🏦 Banking" = "🏦 Banking",
	"➕ C++" = "➕ C++",
	"🔄 Informatica" = "🔄 Informatica",
	"🔃 Talend" = "🔃 Talend",
	"💾 Teradata" = "💾 Teradata",
	"⛓️ Blockchain" = "⛓️ Blockchain",
	"⛏️ Data Mining" = "⛏️ Data Mining",
	"🔄 SSIS" = "🔄 SSIS",
	"🔢 T-SQL" = "🔢 T-SQL",
	"🧠 BCI" = "🧠 BCI",
	"🧬 Neurotech" = "🧬 Neurotech",
	"🔭 Physics" = "🔭 Physics",
	"⚗️ Chemistry" = "⚗️ Chemistry",
	"👥 Modelling" = "👥 Modelling",
	"🔊 TTS" = "🔊 TTS",
	"🎨 Data Studio" = "🎨 Data Studio",
	"🔬 Microservices" = "🔬 Microservices",
	"#️⃣ NumPy" = "#️⃣ NumPy",
	"💼 Consulting" = "💼 Consulting",
	"👥 Bitbucket" = "👥 Bitbucket",
	"💳 Banking" = "💳 Banking",
	"💾 DynamoDB" = "💾 DynamoDB",
	"👁️ Looker" = "👁️ Looker",
	"🔬 Julia" = "🔬 Julia",
	"💾 Bigtable" = "💾 Bigtable",
	"🔢 Hbase" = "🔢 Hbase",
	"🌐 MPP" = "🌐 MPP",
	"🔄 Oozie" = "🔄 Oozie",
	"🔧 Ansible" = "🔧 Ansible",
	"🖥️ Bash" = "🖥️ Bash",
	"🎲 Probability" = "🎲 Probability",
	"💾 MS SQL" = "💾 MS SQL",
	"🗄️ DB2" = "🗄️ DB2",
	"🔺 Angular" = "🔺 Angular",
	"🗺️ Map Reduce" = "🗺️ Map Reduce",
	"🛠️ Maven" = "🛠️ Maven",
	"🗂️ HDFS" = "🗂️ HDFS",
	"🖼️ Image Processing" = "🖼️ Image Processing",
	"👀 Object Recognition" = "👀 Object Recognition",
	"🔍 Feature Extraction" = "🔍 Feature Extraction",
	"📜 OCR" = "📜 OCR",
	"🕹️ 3D Modeling" = "🕹️ 3D Modeling",
	"🏥 Medical Imaging" = "🏥 Medical Imaging",
	"🚗 Autonomous Driving" = "🚗 Autonomous Driving",
	"🕶️ VR" = "🕶️ VR",
	"🖼️🔀 GANs" = "🖼️🔀 GANs",
	"🏗️ 3D Reconstruction" = "🏗️ 3D Reconstruction",
	"🛰️ Lidar" = "🛰️ Lidar",
	"🗺️ SLAM" = "🗺️ SLAM",
	"🎯 Model Training" = "🎯 Model Training",
	"👁️ OpenCV" = "👁️ OpenCV",
	"🔬 Material Science" = "🔬 Material Science",
	"🌐 Diffusion Models" = "🌐 Diffusion Models",
	"☁️ Cloud Computing" = "☁️ Cloud Computing",
	"☕ Caffe" = "☕ Caffe",
	"🗣️ GPT" = "🗣️ GPT",
	"🌐 Open Source" = "🌐 Open Source",
	"🧬 Genetics" = "🧬 Genetics",
	"🌙 Lua" = "🌙 Lua",
	"🌌 Astrophysics" = "🌌 Astrophysics",
	"🔠 TypeScript" = "🔠 TypeScript",
	"🌐 Web Developer" = "🌐 Web Developer",
	"💻 Frontend" = "💻 Frontend",
	"🖥️ Backend" = "🖥️ Backend",
	"🌐 Full Stack" = "🌐 Full Stack",
	"🌐 Stable Diffusion" = "🌐 Stable Diffusion",
	"🔗 GraphQL" = "🔗 GraphQL",
	"💻 Perl" = "💻 Perl",
	"☁️ Google Cloud" = "☁️ Google Cloud",
	"📊 Grafana" = "📊 Grafana",
	"📚 Scikit Learn" = "📚 Scikit Learn",
	"💰 Crypto" = "💰 Crypto",
	"🕸️ Web3" = "🕸️ Web3",
	"💻 Prompt Engineering" = "💻 Prompt Engineering",
	"🎨 DALL-E" = "🎨 DALL-E",
	"🚀 Midjourney" = "🚀 Midjourney",
	"💻 OOP" = "💻 OOP",
	"🏛️ Architecture" = "🏛️ Architecture",
	"🔌 Circuit Design" = "🔌 Circuit Design",
	"📊 D3" = "📊 D3",
	"⚛️ React" = "⚛️ React",
	"🔺 Vue" = "🔺 Vue",
	"🔬 Prototyping" = "🔬 Prototyping",
	"☁️ EC2" = "☁️ EC2",
	"🛍️ E-Commerce" = "🛍️ E-Commerce",
	"🔄 FiveTran" = "🔄 FiveTran",
	"🔬 Scrum" = "🔬 Scrum",
	"🌱 Node" = "🌱 Node",
	"👩‍🏫 Teaching" = "👩‍🏫 Teaching",
	"📈 Predictive Modeling" = "📈 Predictive Modeling",
	"🛠️ Dagster" = "🛠️ Dagster",
	"👩‍💼 Manager" = "👩‍💼 Manager",
	"🎖️ Leadership" = "🎖️ Leadership",
	"🖥️ Unix" = "🖥️ Unix",
	"📁 Parquet" = "📁 Parquet",
	"🤗 HuggingFace" = "🤗 HuggingFace",
	"💱 DeFi" = "💱 DeFi",
	"🔏 Solidity" = "🔏 Solidity",
	"🖥️ Microsoft" = "🖥️ Microsoft",
	"👩‍💻 Technical Support" = "👩‍💻 Technical Support",
	"⏱️ SLAs" = "⏱️ SLAs",
	"📊 Market Research" = "📊 Market Research",
	"💻 ES6" = "💻 ES6",
	"🎲 Monte Carlo" = "🎲 Monte Carlo",
	"👩‍💼 Executive" = "👩‍💼 Executive",
	"🔒 Data Governance" = "🔒 Data Governance",
	"🤖 Explainable AI" = "🤖 Explainable AI",
	"🕸️ Graph Databases" = "🕸️ Graph Databases",
	"🎲 Probalistic Programming" = "🎲 Probalistic Programming",
	"🕰️ Time Series" = "🕰️ Time Series",
	"⚛️ Quantum" = "⚛️ Quantum",
	"🐘 Mahout" = "🐘 Mahout",
	"💎 Ruby" = "💎 Ruby",
	"👩‍🔧 Engineer" = "👩‍🔧 Engineer",
	"💾 Sys Admin" = "💾 Sys Admin",
	"🍔 Full Stack" = "🍔 Full Stack",
	"⚙️ Ops" = "⚙️ Ops",
	"🔒 InfoSec" = "🔒 InfoSec",
	"✍️ Content Writing" = "✍️ Content Writing",
	"🖋️ Copy Writing" = "🖋️ Copy Writing",
	"☁️ SaaS" = "☁️ SaaS",
	"✅ Quality Assurance" = "✅ Quality Assurance",
	"🛍️ Ecommerce" = "🛍️ Ecommerce",
	"⚖️ Legal" = "⚖️ Legal",
	"📝 Admin" = "📝 Admin",
	"🖥️ PHP" = "🖥️ PHP",
	"🔧 Serverless" = "🔧 Serverless",
	"📱 iOS" = "📱 iOS",
	"🖥️ C" = "🖥️ C",
	"💻 C#" = "💻 C#",
	"🤖 Virtual Assistant" = "🤖 Virtual Assistant",
	"🎵 Music" = "🎵 Music",
	"🛍️ Shopify" = "🛍️ Shopify",
	"📝 Wordpress" = "📝 Wordpress",
	"🖥️ Drupal" = "🖥️ Drupal",
	"💰 Payroll" = "💰 Payroll",
	"👩‍🎓 Internship" = "👩‍🎓 Internship",
	"📱 Objective C" = "📱 Objective C",
	"🖥️ HDL" = "🖥️ HDL",
	"🔬 Semiconductors" = "🔬 Semiconductors",
	"🖥️ Hardware" = "🖥️ Hardware",
	"🔌 Electronics" = "🔌 Electronics",
	"📱 TikTok" = "📱 TikTok",
	"📸 Influencer" = "📸 Influencer",
	"👁️ Computer Vision" = "👁️ Computer Vision",
}

export enum JobsCreatedBackupPostTypeOptions {
	"featured" = "featured",
	"aggregated" = "aggregated",
}
export type JobsCreatedBackupRecord = {
	logo?: string
	title?: string
	company?: string
	country?: JobsCreatedBackupCountryOptions[]
	remote?: JobsCreatedBackupRemoteOptions
	tags?: JobsCreatedBackupTagsOptions[]
	location?: string
	min_salary?: string
	max_salary?: string
	apply_link?: string
	updated_ats?: string
	id_ats?: string
	commitment?: string
	region?: string
	company_url?: string
	post_type?: JobsCreatedBackupPostTypeOptions
}

export type UsersRecord = {
	name?: string
	avatar?: string
}

// Response types include system fields and match responses from the PocketBase API
export type JobsResponse = JobsRecord & BaseSystemFields
export type JobsCreatedBackupResponse = JobsCreatedBackupRecord & BaseSystemFields
export type UsersResponse = UsersRecord & AuthSystemFields

// Types containing all Records and Responses, useful for creating typing helper functions

export type CollectionRecords = {
	jobs: JobsRecord
	jobs_created_backup: JobsCreatedBackupRecord
	users: UsersRecord
}

export type CollectionResponses = {
	jobs: JobsResponse
	jobs_created_backup: JobsCreatedBackupResponse
	users: UsersResponse
}