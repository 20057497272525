import React, { useEffect } from 'react';

interface TabData {
  title: string;
  favicon: string;
}

interface TabContentProps {
  inActive: TabData;
  active: TabData;
}

const TabContent: React.FC<TabContentProps> = ({ inActive, active }) => {
  const setFaviconAndTitle = (tabData: TabData) => {
    document.title = tabData.title;

    const link = document.querySelector(`link[rel*='icon']`) as HTMLLinkElement;
    if (link) {
      link.href = tabData.favicon;
    } else {
      const newLink = document.createElement('link');
      newLink.type = 'image/x-icon';
      newLink.rel = 'icon';
      newLink.href = tabData.favicon;
      document.getElementsByTagName('head')[0].appendChild(newLink);
    }
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      setFaviconAndTitle(inActive);
    } else {
      setFaviconAndTitle(active);
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [inActive, active]);

  return null;
};

export default TabContent;
